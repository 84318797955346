import { axiosBase, ApiAuth } from '@/api_utils/axios-base'
import router from '@/router'

export async function login({ dispatch, commit, rootState }, user) {
  try {
    let response = await axiosBase.post('/land/api/token/', user)
    let userLogged = await response.data
    let usuarioId = userLogged.id

    if(userLogged.tipo_usuario === "Distribuidor") {
      let getDistribuidorId = await axiosBase.get(`/personas/api/distribuidor/?usuario_id=${usuarioId}`)
      let idDistribuidor = getDistribuidorId.data[0].id

      userLogged.distribuidorId = idDistribuidor
      // commit('products/setDistributorId', idDistribuidor, { root: true })

      try {
        const shippingTabs = await axiosBase.get(`/checkout/api/tabulador-envios/?distribuidor_id=${idDistribuidor}`)
        const shippingTabsData = await shippingTabs.data

        if (shippingTabsData.length > 0) {
          userLogged.hasTabs = true
        } else {
          userLogged.hasTabs = false
        }

        const warehouseResponse = await axiosBase.get(`/inventarios/api/almacenes/?distribuidor_id=${idDistribuidor}`)
        const warehouse = await warehouseResponse.data

        const defaultWarehouse = warehouse.find(w => w.almacen_predeterminado === true)
        const defaultWarehouseId = defaultWarehouse.id
        const defaultSucursalId = defaultWarehouse.sucursal.id

        userLogged.almacenId = defaultWarehouseId
        userLogged.sucursalId = defaultSucursalId

        //commit('products/setSucursalId', defaultSucursalId, { root: true })
      } catch (error) {
        console.error("Error al obtener almacen", error);
      }
    }
    
    getCsfrToken(commit)
    commit('setLoading', true, {root: true})
    commit('usuario/setTokens', userLogged, {root: true})

    if (userLogged.tipo_usuario === "Cliente") {
      setUserExtraData(commit, userLogged, rootState.products.distributorId)
      dispatch('getWishlistWithPrice')
      dispatch('products/setInWishlistFlag', true, {root: true})
      dispatch('products/setInWishlistFlagFeaturedProducts', true, {root: true})
    }

    setTimeout(() => {
      commit('setLoading', false, {root: true})
      // Se comprueba si el distribuidor ya tiene los datos
      // necesarios: almacenes, suscursales y tabuladores 
      // de envio. Si no los tiene debe completarlos
      // por eso se envia a la pagina "Mis datos"
      if (userLogged.tipo_usuario === "Distribuidor" 
        && !userLogged.almacenId 
        && !userLogged.sucursalId
        && !userLogged.hasTabs) {
          router.push({ name: "Mis datos" })
      }
      // Que se deberia comprobar para los usuarios tipo cliente?
      if(userLogged.tipo_usuario === "Cliente" && !userLogged.email) {
        router.push({ name: "Mis datos" })
      }
    }, 500)
  } catch(err) {
    commit('authError')
    localStorage.removeItem('token')
    console.error("Error en login: ", err);
  }
}

async function getCsfrToken(commit) {
  try{
    let response = await axiosBase.get('/land/get-token/')
    let token = await response.data.token

    commit('usuario/setCsT', token, {root: true})
  } catch(error) {
    console.error("error csfr token", error)
  }
}

export async function refreshToken(context) {
  try {
    let response = await axiosBase.post('/land/api/token/refresh/', {
      refresh: context.state.refreshToken
    })
    let updateAccessToken = response.data.access
    
    //console.log('New access successfully generated', resp)
    context.commit('updateAccess', updateAccessToken)
  } catch(error) {
    console.error('error in refreshToken', error)
    context.commit('authLogout')
  }
}

export function logout({ commit }) {
  commit('setLoading', true, {root: true})
  commit('authLogout')
  commit('cart/removeAllFromCart', null, { root: true })
  setTimeout(() => {
    commit('setLoading', false, {root: true})
  }, 500)
}

export async function setCustomerDiscount({ commit, state }, distributorId) {
  if (state.userData !== null) {
    const response = await axiosBase.get(`/personas/api/cliente/?usuario_id=${state.userData.id}`) 
    const responseData = await response.data
    // console.log("response: ", response);
    if (responseData.length) {
      const discounts = responseData[0].descuento_distribuidor
      if (discounts.length) {
        const distributorDiscount = 
          discounts.find(discount => discount.distribuidor === distributorId)
        if (distributorDiscount) {
          const descuentoCliente = {
            id: distributorDiscount.id,
            nombre_descuento: distributorDiscount.nombre_descuento,
            porcentaje_descuento: distributorDiscount.descuento,
          }
          // console.log("DescuentoCliente: ", descuentoCliente);
          commit('setCustomerDiscount', descuentoCliente)
        }
      }
    }
  }
}

async function setUserExtraData(commit, userData, distributorId) {
  const response = await axiosBase.get(`/personas/api/cliente/?usuario_id=${userData.id}`) 
  const responseData = await response.data
  // console.log("response: ", response);
  if (responseData.length) {
    const addresses = responseData[0].direcciones_entrega
    const discounts = responseData[0].descuento_distribuidor

    if(addresses.length) {
      if (addresses.length === 1) {
        commit('updateSelectedAddress', addresses[0])
      } else {
        let uDirecciones = addresses.find(dir => dir.predeterminado === true)
        commit('updateSelectedAddress', uDirecciones)
      }
    }

    if (discounts.length) {
      const distributorDiscount = 
        discounts.find(discount => discount.distribuidor === distributorId)
      if (distributorDiscount) {
        const descuentoCliente = {
          id: distributorDiscount.id,
          nombre_descuento: distributorDiscount.nombre_descuento,
          porcentaje_descuento: distributorDiscount.descuento,
        }
        // console.log("DescuentoCliente: ", descuentoCliente);
        commit('setCustomerDiscount', descuentoCliente)
      }
    }
  } else {
    console.error("No tiene datos de cliente");
  }
}

export async function validateStripeDistributorAccount({commit, state}) {
  try {
    if (state.userData && state.userData.tipo_usuario === 'Distribuidor') {
      const response = 
        await axiosBase.post('/personas/validar-cuenta-distribuidor-stripe', {id_distribuidor: state.userData.distribuidorId})
      const responseData = await response.data
      if (responseData.error) {
        console.error('el error que se presento: ', responseData.e[0]);
        if (responseData.e[0] === 'email' || responseData.e[0].includes('does not have access to account')) {
          // Es muy probable que aun no se haya creado la cuenta de stripe
          // o que haya problemas con la cuenta
          commit('setStripeAccountComplete', false)
        }
      } else {
        if (responseData.details_submmited) {
          commit('setStripeAccountComplete', true)
        } else {
          commit('setStripeAccountComplete', false)
        }
      }
    } else {
      commit('setStripeAccountComplete', true)
    } 
  } catch (error) {
    console.error(error)
  }
}

export async function fetchWishlist({ commit }) {
  try {
    const response = await ApiAuth.get('/personas/wishlist')
    const responseData = await response.data

    const wishes = responseData.wishlist
    commit('setWishlist', wishes)
  } catch (error) {
    console.error('Error al intentar obtener lista de deseos');
  }
}

export async function getWishlistWithPrice({ dispatch, commit, state, rootState}) {
  try {
    await dispatch('fetchWishlist')
    const wishlist = [...state.wishlist]
    const distributorId = rootState.products.distributorId
    const wishlistWithPrice = []

    for (const wish of wishlist) {
      // console.log('wish', wish);
      const prices = [...wish.producto.precios]
      // console.log('precios:', prices);
      const productId = wish.producto.id
      wish.producto.precio = 1000.00
      for (const priceId of prices) {
        try {
          const response = await ApiAuth.get( `/api/precio-producto-distribuidor/${priceId}/`, {
            params: {
              id_distribuidor: distributorId,
              id_producto: productId
            }
          })
          if (await response.status === 200) {
            const responseData = await response.data
            const distributorPrice = responseData.precio_distribuidor
            wish.producto.precio = parseFloat(distributorPrice)
            break
          } 
        } catch (error) {
          console.error('Error al intentar obtener precio de producto');
        }
      }
      wishlistWithPrice.push(wish)
    }
    commit('setWishlist', wishlistWithPrice)
  } catch (error) {
    console.error('Error al intentar asignar precio a la lista de deseos', error);
  }
}

export async function addProductToWishlist({ commit }, productId) {
  try {
    const response = await ApiAuth.post('/personas/wishlist', {
      id_producto: productId
    })
    const responseData = await response.data
    if (!(responseData.mensaje 
          && responseData.mensaje.includes('producto agregado al wishlist con éxito'))) {
      console.log('No se pudo agregar el proucto a la lista de deseos', responseData);
    }
  } catch (error) {
    console.error('Error al intentar agregar producto a wishlist', error);
  }
}

export async function removeProductOfWishlist({ commit }, productId) {
  try {
    const response = await ApiAuth.delete('/personas/wishlist', {
      data: {
        id_producto: productId
      }
    })
    const responseData = await response.data
    if (!(responseData.mensaje 
          && responseData.mensaje.includes('producto removido con éxito'))) {
      console.log('No se pudo quitar el producto de la lista de deseos');
    }
  } catch (error) {
    console.error('Error al intentar quitar el producto del wishlist');
  }
}
