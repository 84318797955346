import Vue from 'vue'
import Vuex from 'vuex'


import products from '@/modules/products/'
import cart from '@/modules/cart/'
import usuario from '@/modules/usuario'
import { Loader } from '@googlemaps/js-api-loader'
import { config } from "@/api_utils/config.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    loading: false,
    alert: {
      show: false,
      msg: null,
      type: null
    },
    mapsLoader: null,
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
    setLoading: (state, bool) => (state.loading = bool),
    setAlert: (state, payload) => {
      state.alert.show = payload.show 
      state.alert.msg = payload.msg,
      state.alert.type = payload.type
    },
    setMapsLoader: (state) => {
      state.mapsLoader = new Loader({
        apiKey: config.API_KEY,
        version: "weekly",
      })
    },
  },
  modules: {
    products,
    cart,
    usuario
  }
})
