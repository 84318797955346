import { Api } from '@/api_utils/axios-base'
import { ApiAuth, axiosBase } from '../../api_utils/axios-base'
import { queryInventarios } from '../../api_utils/graphqlQueries'

///
/// ==================================
/// AUTOR: Tania Lucero Marin Sosa
/// RESEÑANTE: Raul Altamirano Lozano
/// FECHA: 08/07/2022
/// COMENTARIOS:
/// - Se hizo un preba para intentar reemplazar la ruta 
///   inventario-sucursal-lite por Graphql
/// 
export async function fetchProducts({ dispatch, commit, state, rootState, rootGetters }, loadingIndicator) {
  /*
    Actualizacion: Se utiliza distributorId y sucursal para obtener
    los inventarios correspondientes. 
    Al arreglo de valores obtenidos se le hacen dos adiciones a los
    productos. Primero se agregan los descuentos y posteriormente se
    se revisan los precios para extraer el correspondiente
    al distribuidor cuyo id corresponda con distributorId 
  */
  try{
    if (loadingIndicator) {
      commit('setLoading', true, {root: true})
    }
    commit('setProductLoading', true)
    const distributorId = state.distributorId
    const sucursalId = state.sucursalId
    //console.log('sucursalId', sucursalId)
    const productsResponse = await Api.post('/inventarios/inventario-sucursal-lite', {id_sucursal: sucursalId}) 
    // const productsResponse = await Api.post('/graphql/', {
    //   query: queryInventarios
    // }) 
    const productsResponseData = await productsResponse.data
    // console.log('productsResponseData: ', productsResponseData);
    const inventory = productsResponseData.respuesta.respuestas
    const inventoryWithPrice = []

    const descuentosResp = await axiosBase.post('/checkout/productos-con-descuento', {id_distribuidor: distributorId})
    const descuentos = await descuentosResp.data

    descuentos.forEach(descuento => {
      /* Recorremos cada producto que hay en ese descuento. */
      descuento.productos.forEach(idDescuentoProduct => {
        inventory.every(currentProduct => {
          if(currentProduct.producto.id === idDescuentoProduct) {
            if (descuento.vigencia) {
              if(!currentProduct.descuento) {
                currentProduct.descuento = []
              }
              currentProduct.descuento.push(descuento)
            }
            return false
          }
          return true
        })
      })
    })
    
    // Obtenemos los datos de almacen en caso de que el objeto
    // inventario no tenga almacen en el mismo nivel que
    // producto, dicho caso se ha observado se ha encontrado en
    // resultados devueltos por las rutas /
    let almacen = null
    if (inventory.length !== 0) {
      if (inventory[0].almacen === null || inventory[0].almacen === undefined) {
        const almacenId = inventory[0].producto.almacen.id
        const responseAlmacenes = await Api.get(`/inventarios/api/almacenes/${almacenId}/`)
        const responseAlmacenesData = await responseAlmacenes.data
        //const filterAlmacen = responseAlmacenesData.find(resAlmacen => resAlmacen.sucursal.id === sucursalId)
        //console.log('Almacen encontrado? ', responseAlmacenesData);
        if (responseAlmacenesData) {
          almacen = responseAlmacenesData
        }
      }
    }
    
    // Se extrae el precio correspondiente al distribuidor seleccionado
    // para no romper todo agrego el elemento precio a cada producto
    // que es como estaba antes del cambio del modelo de precios
    let wishlist = []
    if (rootGetters['usuario/loggedIn'] 
        && rootGetters['usuario/esCliente']) {
      // console.log('Obteniendo lista de deseos');
      await dispatch('usuario/getWishlistWithPrice', null, { root: true })
      wishlist = rootState.usuario.wishlist
    }
    //console.log('wishlist fetchproducts', wishlist);

    for (const i of inventory) {
      if (i.almacen === null || i.almacen === undefined) {
        i.almacen = almacen
      }

      if (i.producto.precios.length === 0) {
        i.producto.precio = 0.00
      } else {
        // console.log("Error", i, "Distribuidor", distributorId)
        const price = i.producto.precios.find(pr => pr.distribuidor === distributorId)
        // console.log("Precio Obtenido", price)
        if (price) {
          const images = i.producto.imagenes
          const webpImages = images.filter(img => img.imagen.includes('.webp?'))
          const pngImages = images.filter(img => img.imagen.includes('.png?'))
          i.producto.imagenes = webpImages ? shuffleImages(webpImages) : []
          i.producto.imagenesPNG = pngImages ? shuffleImages(pngImages) : []

          i.producto.precio = price.precio_distribuidor
          const productWithPrice = {...i, inWishlist: false}
          // Ya que la lista de deseos esta estrictamente relacionada
          // con el token de acceso del usuario logueado es necesario
          // verificar justo si esta logueado y tambien si es cliente
          if (rootGetters['usuario/loggedIn'] && rootGetters['usuario/esCliente']) {
            const productWished = wishlist.find(wish => wish.producto.id === productWithPrice.producto.id)
            if (productWished) {
              productWithPrice.inWishlist = true
            }
          }
          inventoryWithPrice.push(productWithPrice)
        }
      }
    }

    // console.log('invetoryWithPrice:', inventoryWithPrice);
    const countItems = inventoryWithPrice.length
    commit('setTotalItems', countItems)

    commit('setInventoryBySucursal', inventoryWithPrice)
    commit('setProductLoading', false)
  }
  catch(error) {
    console.error("error getting products", error)
  }
  finally {
    if (loadingIndicator) {
      setTimeout(() => {
        commit('setLoading', false, {root: true})
      }, 500)
    }
  }
}

export async function fetchPaginatedProducts({commit, state}, paginationData) {
  try {
    commit('setProductLoading', true)
    const distributorId = paginationData.distributorId
    const sucursalId = paginationData.sucursalId
    const page = paginationData.page
    const productsResponse = await Api.get(`/inventarios/api/inventarios-sucursal/?id_sucursal=${sucursalId}&page=${page}`) 
    const inventory = await productsResponse.data.results

    let descuentos = state.descuentos
    if (descuentos.length === 0) {
      const descuentosResp = await axiosBase.post('/checkout/productos-con-descuento', {id_distribuidor: distributorId})
      descuentos = await descuentosResp.data
    }

    //const descuentosResp = await axiosBase.post('/checkout/productos-con-descuento', {id_distribuidor: distributorId})
    //const descuentos = await descuentosResp.data

    descuentos.forEach(descuento => {
      /* Recorremos cada producto que hay en ese descuento. */
      descuento.productos.forEach(idDescuentoProduct => {
        inventory.every(currentProduct => {
          if(currentProduct.producto.id === idDescuentoProduct) {
            if (descuento.vigencia) {
              if(!currentProduct.descuento) {
                currentProduct.descuento = []
              }
              currentProduct.descuento.push(descuento)
            }
            return false
          }
          return true
        })
      })
    })
    // Se extrae el precio correspondiente al distribuidor seleccionado
    // para no romper todo agrego el elemento precio a cada producto
    // que es como estaba antes del cambio del modelo de precios
    inventory.forEach(i => {
      
      if (i.producto.precios.length === 0) {
        i.producto.precio = 1000.00
      } else {
        const price = i.producto.precios.find(pr => pr.distribuidor === distributorId)
        i.producto.precio = price ? price.precio_distribuidor : 1000.00
      }
    })

    commit('appendPaginatedProducts', inventory)
    commit('setProductLoading', false)
  } catch (error) {
    console.error('Ocurrio un error al intentar obtener inventario paginado')
  }
}

export async function fetchInventoryBySucursal({ commit, state, rootState }, loadingIndicator) {
  try {
    if (loadingIndicator) {
      commit('setLoading', true, {root: true})
    }
    commit('setProductLoading', true)
    const distributorId = state.distributorId
    const sucursalId = state.sucursalId
    // console.log("distributorId: ", distributorId)
    // console.log("sucursalId: ", sucursalId);
    const productsResponse = await Api.get(`/inventarios/api/inventarios-sucursal/?id_sucursal=${sucursalId}`) 
    const inventoryCommon = await productsResponse.data

    // console.log("inventoryCommon: ", inventoryCommon);

    const descuentosResp = await axiosBase.post('/checkout/productos-con-descuento', {id_distribuidor: distributorId})
    const descuentos = await descuentosResp.data

    descuentos.forEach(descuento => {
      /* Recorremos cada producto que hay en ese descuento. */
      descuento.productos.forEach(idDescuentoProduct => {
        inventoryCommon.every(currentProduct => {
          if(currentProduct.producto.id === idDescuentoProduct) {
            if(!currentProduct.descuento) {
              currentProduct.descuento = []
            }
            currentProduct.descuento.push(descuento)
            return false
          }
          return true
        })
      })
    })
    inventoryCommon.forEach(inventory => {
      if (inventory.producto.precios.length === 0) {
        inventory.producto.precio = 0.00
      } else {
        const price = inventory.producto.precios.find(pr => pr.distribuidor === distributorId)
        inventory.producto.precio = price ? price.precio_distribuidor : 0.00
      }
    })
    // console.log("inventoryCommon: ", inventoryCommon);

    commit('setInventoryBySucursal', inventoryCommon)
    commit('setProductLoading', false)
  } catch (error) {
    // console.log("Ocurrio un error en fetchInventoryBySucursal", error);
  } finally {
    if (loadingIndicator) {
      setTimeout(() => {
        commit('setLoading', false, {root: true})
      }, 500)
    }
  }
}

export async function getCategories({ commit }) {
  try {
    let respCategorias = await Api.get('/api/categorias/')
    let categorias = await respCategorias.data
    const activeCategories = categorias.filter(category => category.estatus_sistema === true)
    commit('setCategories', activeCategories)
  } catch(error) {
    console.error("error getting categories", error)
  }
}

export function setDistributorId({ commit }, distributorId) {
  commit('setDistributorId', distributorId)
}

export function setSucursalId({ commit }, sucursalId) {
  commit('setSucursalId', sucursalId)
}

export async function getSucursals({ commit, rootState }) {
  try {
    const loader = rootState.mapsLoader
    const sucursalesRes = await Api.get('/personas/api/sucursal/')
    const sucursales = await sucursalesRes.data
    const sucursalesSinPosicion = 
      sucursales.filter(sucursal => !sucursal.latitude && !sucursal.longitud)
    
    let coordenadas = {
      id_sucursal: null,
      latitud: null,
      longitud: null
    }
    
    for (const sucursal of sucursalesSinPosicion) {
      const sucursalAddress = `${sucursal.direccion.calle},${sucursal.direccion.numero_exterior},${sucursal.direccion.colonia},${sucursal.direccion.codigo_postal},${sucursal.direccion.estado},${sucursal.direccion.ciudad}`
      await loader.load().then(async () => {
        const geocoder = new google.maps.Geocoder()
        await geocoder.geocode(
          {'address': sucursalAddress}, 
          async function(results, status) {
            coordenadas.id_sucursal = sucursal.id
            coordenadas.latitud = results[0].geometry.location.lat()
            coordenadas.longitud = results[0].geometry.location.lng()
            const actualizacionRes = await axiosBase.post('/personas/actualizar-coordenadas-sucursal', coordenadas)
            const actualizacionData = await actualizacionRes.data
            if (actualizacionRes.status === 200) {
              sucursal.latitud = actualizacionData.latitud
              sucursal.longitud = actualizacionData.longitud
              const indexToUpdate = 
                sucursales.findIndex(s => s.id === actualizacionData.Sucursal)
              sucursales[indexToUpdate].latitud = actualizacionData.latitud
              sucursales[indexToUpdate].longitud = actualizacionData.longitud
            }
        })
      })
    }
    commit('setSucursals', sucursales)
  } catch (error) {
    
  }
}

export async function getProductOptions({ commit }) {
  try {
    const response = await Api.get('/api/opciones-producto/')
    const responseData = await response.data
    
    commit('setProductOptions', responseData)
  } catch (error) {
    console.error('Error al obtener opciones productos', error)
  }
}

export async function getFeaturedProducts({ dispatch, commit, state, rootState, rootGetters }) {
  try {
    const sucursalId = state.sucursalId
    const distributorId = state.distributorId
    const response = await Api.get(`/inventarios/inventario-sucursal-destacados/${sucursalId}`)
    const responseData = await response.data
    //console.log('response data destacados: ', responseData);
    const featuredProducts = responseData.respuesta.respuestas
    const featuredProductsWithPrice = []

    let wishlist = []
    if (rootGetters['usuario/loggedIn'] 
        && rootGetters['usuario/esCliente']) {
      // console.log('Obteniendo lista de deseos featured');
      await dispatch('usuario/getWishlistWithPrice', null, { root: true })
      wishlist = rootState.usuario.wishlist
    }
    // console.log('wishlist featuredProduct', wishlist);
    let almacen = null
    if (featuredProducts.length !== 0) {
      if (featuredProducts[0].almacen === null || featuredProducts[0].almacen === undefined) {
        const almacenId = featuredProducts[0].producto.almacen.id
        const responseAlmacenes = await Api.get(`/inventarios/api/almacenes/${almacenId}/`)
        const responseAlmacenesData = await responseAlmacenes.data
        //const filterAlmacen = responseAlmacenesData.find(resAlmacen => resAlmacen.sucursal.id === sucursalId)
        //console.log('Almacen encontrado destacados? ', responseAlmacenesData);
        if (responseAlmacenesData) {
          almacen = responseAlmacenesData
        }
      }
  
    }
    
    for(const fp of featuredProducts) {
      if (fp.almacen === null || fp.almacen === undefined) {
        fp.almacen = almacen
      }
      if (fp.producto.precios.length === 0) {
        fp.producto.precio = 1000.00
      } else {
        const price = fp.producto.precios.find(pr => pr.distribuidor === distributorId)
        //console.log('price de distribuidor', price);
        if (price) {
          const images = fp.producto.imagenes
          const webpImages = images.filter(img => img.imagen.includes('.webp?'))
          const pngImages = images.filter(img => img.imagen.includes('.png?'))

          fp.producto.imagenes = webpImages ? shuffleImages(webpImages) : []
          fp.producto.imagenesPNG = pngImages ? shuffleImages(pngImages) : []

          fp.producto.precio = price.precio_distribuidor
          const productWithPrice = {...fp, inWishlist: false}
          // Ya que la lista de deseos esta estrictamente relacionada
          // con el token de acceso del usuario logueado es necesario
          // verificar justo si esta logueado y tambien si es cliente
          if (rootGetters['usuario/loggedIn'] && rootGetters['usuario/esCliente']) {
            const productWished = wishlist.find(wish => wish.producto.id === productWithPrice.producto.id)
            if (productWished) {
              productWithPrice.inWishlist = true
            }
          }
          featuredProductsWithPrice.push(productWithPrice)
        }
      }
    }
    //console.log('featuredProductsWithPrice', featuredProductsWithPrice);
    commit('setFeaturedProducts', featuredProductsWithPrice)
  } catch (error) {
    console.error('Error al obtener los productos destacados', error)
  }
}

export async function getPaginatedProductSearch({ commit, state }, page) {
  try {
    commit('setProductLoading', true)
    const sucursalId = state.sucursalId
    const lastSearch = state.lastSearch
    const distributorId = state.distributorId
    const urlSearch = `/inventarios/api/inventarios-nombre-producto/?id_sucursal=${sucursalId}&nombre_producto=${lastSearch}&page=${page}`
    const response = await axiosBase.get(urlSearch)
    const responseData = await response.data
    const results = responseData.results
    const resultsWithPrice = []
    results.forEach(r => {
      if (r.producto.precios.length === 0) {
        r.producto.precio = 1000.00
      } else {
        const price = r.producto.precios.find(pr => pr.distribuidor === distributorId)
        if (price) {
          const images = r.producto.imagenes
          const webpImages = images.filter(img => img.imagen.includes('.webp?'))
          const pngImages = images.filter(img => img.imagen.includes('.png?'))
          
          r.producto.imagenes = webpImages ? shuffleImages(webpImages) : []
          r.producto.imagenesPNG = pngImages ? shuffleImages(pngImages) : []
          
          r.producto.precio = price.precio_distribuidor
          const productWithPrice = {...r}
          resultsWithPrice.push(productWithPrice)
        }
      }
    })
    commit('appendProductSearch', resultsWithPrice)
    commit('setProductLoading', false)
  } catch (error) {
    console.log('Error al realizar busqueda paginada');
  }
}

export async function setProductSearch({ commit }, products) {
  try {
    commit('setProductSearch', products)
  } catch (error) {
    console.error('Error al asignar resultados de busqueda: ', error)
  }
}

export async function setIsSearch({commit}, bool) {
  try {
    commit('setIsSearch', bool)
  } catch (error) {
    console.error('Error al asignar bandera de busqueda: ', error)
    
  }
}

export async function setInWishlistFlag({ commit, state, rootState }, bool) {
  const productsWithPrice = [...state.products]
  const wishlist = [...rootState.usuario.wishlist]

  wishlist.forEach(wish => {
    const productWishedIndex = productsWithPrice.findIndex(product => product.producto.id === wish.producto.id)
    if (productWishedIndex !== -1) {
      productsWithPrice[productWishedIndex].inWishlist = bool
    }
  })

  commit('setInventoryBySucursal', productsWithPrice)
}

export async function setInWishlistFlagFeaturedProducts({ commit, state, rootState }, bool) {
  const productsWithPrice = state.featuredProducts !== null ? [...state.featuredProducts] : []
  // console.log('featured products setWishlistflag', productsWithPrice);
  const wishlist = [...rootState.usuario.wishlist]

  wishlist.forEach(wish => {
    const productWishedIndex = productsWithPrice.findIndex(product => product.producto.id === wish.producto.id)
    // console.log('productWishedIndex', productWishedIndex);
    if (productWishedIndex !== -1) {
      productsWithPrice[productWishedIndex].inWishlist = bool
    }
  })

  commit('setFeaturedProducts', productsWithPrice)
}

function shuffleImages(arraySrc) {
  const shuffledList = arraySrc.sort(() => Math.random() - 0.5)
  return shuffledList
}