import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/construfacil'
  },
  {
    path: '/construfacil',
    name: 'Construfácil',
    component: () => import(/* webpackChunkName: "Construfácil" */ '../pages/Landing/Index.vue'),
    meta: {
      requiresSucursal: true
    }
  },
  {
    path: '/productos',
    name: 'Productos',
    props(route) {
      return { filtro: route.query.filtro }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Products" */ '../pages/Products/Index.vue'),
    meta: {
      requiresSucursal: true
    }
  },
  {
    path: '/marcas',
    name: 'Marcas',
    component: () => import(/* webpackChunkName: "Marcas"*/ '../pages/Marcas/Index.vue'),
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import(/* webpackChunkName: "Carrito"*/ '../pages/Carrito/Index.vue'),
  },
  {// ruta fake para probar Auth
    path: '/pedidos',
    name: 'Mis pedidos',
    component: () => import(/* webpackChunkName: "Mis_Pedidos" */'../pages/Cuenta/Cliente/Pedidos.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/directorio-tiendas',
    name: 'Directorio de tiendas',
    component: () => import(/* webpackChunkName: "Tiendas" */'../pages/Tiendas/Index.vue')
  },
  {
    path: '/aviso-privacidad',
    name: 'aviso-de-privacidad',
    component: () => import(/* webpackChunkName: "Tiendas" */'../pages/Footer/AvisoPrivacidad.vue')
  },
  {
    path: '/mis-datos',
    name: 'Mis datos',
    component: () => import(/* webpackChunkName: "Mis_Datos" */'../pages/Cuenta/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mis-pagos',
    name: 'Mis pagos',
    component: () => import(/* webpackChunkName: "Mis_Pagos" */'../pages/Cuenta/Cliente/TarjetasPagos.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mi-lista-de-deseos',
    name: 'Lista de deseos',
    component: () => import(/* webpackChunkName: "Wishlist" */'../pages/Cuenta/Cliente/Wishlist.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/descuentos',
    name: 'Descuentos',
    component: () => import(/* webpackChunkName: "Descuentos" */'../pages/Cuenta/Distribuidor/Descuentos.vue')
  },
  {
    path: '/tabulador-envios',
    name: 'Tabulador de Envíos',
    component: () => import(/* webpackChunkName: "Tabulador" */'../pages/Cuenta/Distribuidor/TabuladorEnvios.vue')
  },
  {
    path: '/actualizar-inventarios',
    name: 'Actualizar inventarios',
    component: () => import(/* webpackChunkName: "Actualizar_Inventarios" */'../pages/Cuenta/Distribuidor/ActualizarInventarios.vue')
  },
  {
    path: '/precios-producto',
    name: 'Precios de productos',
    component: () => import(/* webpackChunkName: "Actualizar_Precios" */'../pages/Cuenta/Distribuidor/PreciosProducto.vue')
  },
  {
    path: '/unidades-por-tonelada',
    name: 'Unidades por tonelada',
    component: () => import(/* webpackChunkName: "Unidades_Tonelada" */'../pages/Cuenta/Distribuidor/EquivalenciaMedidas.vue')
  },
  {
    path: '/distribuidores',
    name: 'Distribuidores',
    component: () => import(/* webpackChunkName: "Distribuidores" */'../pages/Cuenta/Administrador/Distribuidores.vue')
  },
  {
    path: '/templates',
    name: 'Administrar templates',
    component: () => import(/* webpackChunkName: "Templates" */'../pages/Cuenta/Administrador/ActualizarTemplates.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "Checkout" */'../pages/Carrito/Checkout.vue')
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    redirect: '/dashboard/estadisticas-generales',
    component: () => import(/* webpackChunkName: "Dashboard" */'../pages/DashBoard/Index.vue'),
    meta: {
      authUsers: true
    },
    children: [
      {
        path: 'estadisticas-generales',
        component: () => import(/* webpackChunkName: "Dashboard_Estadisticas" */'../pages/DashBoard/Estadisticas/Index.vue')
      },
      {
        path: 'inventarios',
        component: () => import(/* webpackChunkName: "Dashboard_Inventarios" */'../pages/DashBoard/Inventarios/Index.vue')
      },
      {
        path: 'estatus',
        component: () => import(/* webpackChunkName: "Dashboard_Estatus" */'../pages/DashBoard/Estatus/Index.vue'),
        meta: {
          esDistribuidor: true
        }
      },
      {
        path: 'cancelaciones',
        component: () => import(/* webpackChunkName: "Dashboard_Cancelaciones" */'../pages/DashBoard/Cancelaciones/Index.vue'),
        meta: {
          esDistribuidor: true
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0}
    }
  }
})

export default router
