export default {
  accessToken: localStorage.getItem('access_token') || null,
  refreshToken: localStorage.getItem('refresh_token') || null,
  csT: localStorage.getItem('csT') || null,
  status: '',
  userData: JSON.parse(localStorage.getItem('usuario')) || null,
  userAddress: JSON.parse(localStorage.getItem('userAddress')) || null,
  customerDiscount: JSON.parse(localStorage.getItem('customerDiscount')) || null,
  stripeAccountComplete: null,
  wishlist: []
}
