export function setProducts (state, products) {
  state.products = products
}

export function setCategories (state, categories) {
  state.categories = categories
}

export function setDistributorId (state, distributorId) {
  localStorage.setItem('distributorId', distributorId)
  state.distributorId = distributorId
}

export function setSucursalId (state, sucursalId) {
  localStorage.setItem('sucursalId', sucursalId)
  state.sucursalId = sucursalId
}

export function setInventoryBySucursal (state, inventory) {
  state.products = inventory
}

export function setSucursals(state, sucursals) {
  state.sucursals = sucursals
}

export function setProductOptions(state, options) {
  state.productOptions = options
}

export function appendPaginatedProducts(state, inventory) {
  state.products.push(...inventory)
}

export function setTotalItems(state, total) {
  state.totalItems = total
}

export function setLastPageInventory(state, lastPage) {
  state.lastPageInventory = lastPage
}

export function setFeaturedProducts(state, products) {
  state.featuredProducts = products
}

export function setProductSearch(state, products) {
  state.productSearch = products
}

export function appendProductSearch(state, products) {
  state.productSearch.push(...products)
}

export function setIsSearch(state, bool) {
  state.isSearch = bool
}

export function setLastSearch(state, text) {
  state.lastSearch = text
}

export function setProductLoading(state, bool) {
  state.productLoading = bool
}