export function loggedIn(state) {
  return state.accessToken !==  null
}

export function accessT(state) {
  return state.accessToken
}

export function csrfT(state) {
  return state.csT
}

export function esDistribuidor(state) {
  if(state.userData) {
    return state.userData.tipo_usuario === "Distribuidor" 
  } else {
    return false
  }
}

export function esTyasa(state) {
  if (state.userData) {
    return state.userData.tipo_usuario === "Tyasa"
  } else {
    return false
  }  
}

export function isAuth(state) {
  return state.userData.tipo_usuario === "Distribuidor" ||
    state.userData.tipo_usuario === "Tyasa"
}

export function esCliente(state) {
  if (state.userData) {
    return state.userData.tipo_usuario === "Cliente"
  } else {
    return false
  }
}

export function showingCart(state) {
  if (state.accessToken === null) {
    return true
  }
  if (state.userData) {
    return state.userData.tipo_usuario === "Cliente"
  }
  return false
}

export function userFullname(state) {
  return `${state.userData.nombre} ${state.userData.apellido}`
}

export function distributorBasicComplete(state) {
  if (state.userData) {
    return state.userData.tipo_usuario === "Cliente" 
    ? true 
    : state.userData.distribuidorId 
      && state.userData.almacenId 
      && state.userData.sucursalId
      && state.userData.hasTabs
  } else {
    return true
  }
}