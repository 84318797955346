<template>
  <v-app>
    <div v-if="!loading">
      <app-bar/>
      <v-main class="pt-0">
        <v-alert
          v-show="hideAlert"
          dismissible
          :type="alert.type"
          rounded="0"
          class="my-0"
        >
        {{ alert.msg }}
        </v-alert>
        <div>
          <v-fade-transition mode="out-in">
            <router-view/>
          </v-fade-transition>
        </div>
      </v-main>
      <footer-app></footer-app>
    </div>
    <div
      style="width:100%; height: 100%; "
      class="d-flex align-center justify-center background-element-secondary"
      v-else-if="loading"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
        :width="7"
      />
    </div>
  </v-app>
</template>

<script>
import shopLayout from '@/components/ShopLayout'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import store from './store'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Construfácil',
    // all titles will be injected into this template
    titleTemplate: '%s | Construfácil'
  },
  components: {
    shopLayout,
    AppBar: () => import('@/components/AppBar.vue'),
    Drawer: () => import('@/components/Drawer.vue'),
    FooterApp: () => import('@/components/Footer.vue')
  },
  data () {
    return {
      coordenadas: {
        id_sucursal: null,
        latitud: null,
        longitud: null
      },
      userLat: null,
      userLng: null,
    }
  },
  created () {
    this.setMapsLoader()
    this.getCategories()
    this.getSucursals()
    this.getUserPosition()
  },
  methods: {
    /*
     * geoDisponible() checa si la funcionalidad es soportada por el navegador del usuario.
     * getUserPosition() es el proceso de preguntarle al usuario si permite que sepamos su ubicación,
     * si lo hace, entonces obtenemos su código postal y lo guardamos en el store de Vue, y este dato
     * es para que cuando se agregue la funcionalidad de obtener el distribuidor que se le mostrará al cliente,
     * este dato es el que se usará para saber las cercanias.
    */
    ...mapMutations(['setMapsLoader']),
    ...mapActions('products', ['fetchProducts', 'getCategories', 'setDistributorId', 'setSucursalId', 'getSucursals', 'getFeaturedProducts']),
    getUserPosition() {
      if(!navigator.geolocation) {
        console.info('Tu navegador no soporta la geolocalización')
        return
      }
      if(this.distributorId === null && this.sucursalId === null) {
        if (this.userAddress) {
          this.getCoordsbyStringAddress()
        } else {
          navigator.geolocation.getCurrentPosition(
            position => {
              this.userLat = position.coords.latitude
              this.userLng = position.coords.longitude
              this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
            },
            error => {
              console.log('Error obteniendo posicion');
              console.log(error.message)
              
              this.manualSelectionOfSucursal('Lo sentimos, no se pudo obtener la posición. ')
            },
          )
        }
      } else {
        if (this.products.length) {
          this.getFeaturedProducts()
          return
        }
        this.getFeaturedProducts()
        this.fetchProducts()
      }
    },
    async getCoordsbyStringAddress() {
      const address = `${this.userAddress.calle},${this.userAddress.colonia},${this.userAddress.codigo_postal},${this.userAddress.estado},${this.userAddress.ciudad}`

      this.mapsLoader.load().then(async () => {
        const geocoder = new google.maps.Geocoder()
        await geocoder.geocode({address: address}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              this.userLat = results[0].geometry.location.lat
              this.userLng = results[0].geometry.location.lng
            }
          }
        })
      })
      await this.getStreetAddressFrom(this.userLat, this.userLng)
    },
    async getStreetAddressFrom(lat, long) {
      try{
        const latlng = {
          lat: lat,
          lng: long
        }

        this.mapsLoader.load().then(() => {
          const geocoder = new google.maps.Geocoder()
          geocoder.geocode({location: latlng}, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                 //console.log("completeAddress: ", results[0].formatted_address)
              }
            }
          })
        })
        this.calculateDistribuitorIdByUserPosition()

      } catch (error) {
        console.log(error.message)
        this.manualSelectionOfSucursal('Lo sentimos, no se pudo ' 
           + 'establecer la sucursal automáticamente. ')
      }
    },
    calculateDistribuitorIdByUserPosition() {
      const destinations = []
      this.sucursals.forEach(sucursal => {
        destinations.push(`${sucursal.latitud},${sucursal.longitud}`)
      });

      const originStr = `${this.userLat},${this.userLng}`
      
      this.mapsLoader.load().then(() => {
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [originStr],
            destinations: destinations,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              alert('Error was: ' + status);
            } else {
              const distances = []
              for (var i = 0; i < response.destinationAddresses.length; i++) {
                const d = {
                  destination: "", 
                  distance: 1000000000000
                }
                if (response.rows[0].elements[i].status === 'OK') {
                  d.destination = response.destinationAddresses[i]
                  d.distance = response.rows[0].elements[i].distance.value
                }
                distances.push(d)
              }

              const sortedDistances = [...distances].sort((a, b) => {
                return a.distance > b.distance ? 1 : -1
              })
              const lowValueDistance = parseInt(sortedDistances[0].distance)
              let indexSucursal = distances.findIndex(dis => dis.distance === lowValueDistance)
              const idDistribuidor = this.sucursals[indexSucursal].distribuidor.id
              const idSucursal = this.sucursals[indexSucursal].id
              this.setDistributorId(idDistribuidor)
              this.setSucursalId(idSucursal)
              this.getFeaturedProducts()
              this.fetchProducts()            
            }
          })
      })
    },
    manualSelectionOfSucursal(msg) {
      if (this.sucursalId === null) {
        alert(msg + ' ' + 'Para brindarte el mejor servicio por favor ' 
              + 'selecciona la sucursal más cercana a tu ubicación')
        this.$router.push({name: 'Directorio de tiendas'})
      }
    }
  },
  computed: {
    ...mapState(['loading', 'alert', 'mapsLoader']),
    ...mapState('usuario', ['userAddress']),
    ...mapState('products', ['sucursalId', 'distributorId', 'products', 'sucursals']),
    ...mapGetters('products', ['hasSucursal']),
    hideAlert() {
      setTimeout(() => {
        store.commit('setAlert', {show: false, msg: null, type: null})
      },3000)
      return this.alert.show
    }
  }
}

</script>

<style>
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./assets/fonts/Montserrat-Bold.ttf') format('truetype')
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: #2D4761;
}
body::-webkit-scrollbar-thumb {
  background-color: #FF3700;
  border-radius: 20px;
  border: 1px solid #2D4761;
}
</style>
