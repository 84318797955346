import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
//import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'

require('./plugins')

require('decimal.js-light')

require('./assets/css/main.css')

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters["usuario/loggedIn"]) {
      store.commit('setAlert', {
        show: true,
        msg: "Por favor inicia sesión o regístrate",
        type: "warning"
      })
      next({ name: 'Construfácil' })
    } else { 
      next()
    }
  } else if(to.matched.some(record => record.meta.authUsers)) {
    if(!store.getters["usuario/isAuth"]) {
      store.commit('setAlert', {
        show: true,
        msg: "Clientes no puede entrar a esta sección",
        type: "warning"
      })
      next({ name: 'Construfácil'})
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.esDistribuidor)) {
    if(!store.getters["usuario/esDistribuidor"]) {
      store.commit('setAlert', {
        show: true,
        msg: "Solo Distribuidores!",
        type: "warning"
      })
      next({ name: 'DashBoard'})
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresSucursal)) {
    if (!store.getters["products/hasSucursal"]) {
      store.commit('setAlert', {
        show: true,
        msg: "Para brindarte el mejor servicio por favor elije una sucursal",
        type: "warning"
      })
      //next({name: 'Directorio de tiendas'})
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})
