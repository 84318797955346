export function addProduct (state, product) {
  const productInCart = state.cart.find(({id}) => id === product.id)
  state.productsCount += 1
  if(!productInCart) {
    const copy = Object.assign({}, product)
    copy.qty = 1
    state.cart.push(copy)
  } else {
    productInCart.qty += 1
  }
}

export function removeProductFromCart (state, product) {
  if(product.qty > 1) {
    let prodInCart = state.cart.find(prod => prod.id === product.id)
    state.productsCount -= 1
    prodInCart.qty -= 1
  } else {
    state.productsCount -= 1
    state.cart = state.cart.filter(({id}) => id !== product.id)
  }
}

export function removeAllFromCart(state) {
  state.cart = []
  state.productsCount = 0
}

export function setDistancia(state, distancia) {
  state.distanciaKm = distancia
}
