export default {
  products: [],
  sucursals: [],
  inventoryBySucursal: [],
  productOptions: [],
  categories: [],
  distributorId: parseInt(localStorage.getItem('distributorId')) || null,
  sucursalId: parseInt(localStorage.getItem('sucursalId')) || null,
  descuentos: [],
  totalItems: 0,
  lastPageInventory: 0,
  featuredProducts: null,
  productSearch: [],
  isSearch: false,
  lastSearch: '',
  productLoading: false,
}
