import axios from 'axios'
import store from '../store'

//const APIUrl = 'http://127.0.0.1:8000'
const APIUrl = 'https://backend.construfacil.com.mx'

//solo en modulo usuario para login y tokens
const axiosBase = axios.create({
  baseURL: APIUrl,
  headers: { contentType: 'application/json'}
})

const Api = axios.create({
  baseURL: APIUrl,
})

const ApiAuth = axios.create({
  baseURL: APIUrl,
  headers: { contentType: 'application/json'}
})

const authRequest = axios.create({
  baseURL: APIUrl,
})

/* Resolviendo este problema: https://forum.djangoproject.com/t/why-are-cookie-secure-settings-defaulted-to-false/1133 */
// authRequest.interceptors.request.use(function(config) {
//   config.headers = {'X-CSRFToken': store.getters["usuario/csrfT"]}
//   return config
// }, function(error) {
//   return Promise.reject(error)
// })

ApiAuth.interceptors.request.use(function(config) {
  config.headers = {'Authorization': `Bearer ${store.state.usuario.accessToken}`}
  return config
}, function(error) {
    return Promise.reject(error)
})

ApiAuth.interceptors.response.use((response) => {
  // Cuando todo va bien solamente regresamos la respuesta:
  return response
}, async (error) => {
  // cualquier error que no sea 401 lo retornamos
  if(error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error)
    });
  }
  // Cuando el error es 401 y el usuario NO es anónimo
  if(store.getters["usuario/loggedIn"]) {
    return store.dispatch("usuario/refreshToken")
    // En el action refreshToken validamos si el refreshToken sigue válido
      .then(() => {
        console.log("Ya cambio el token")
        const config = error.config
        config.headers['Authorization'] = `Bearer ${store.state.usuario.accessToken}`
        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        })
      }).catch((error) => {
        Promise.reject(error)
      })
  }
})

export {
  axiosBase,
  Api,
  ApiAuth,
  authRequest,
  APIUrl
  //postStripe
}
