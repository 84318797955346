import router from '../../router'

export function setTokens(state, sessionData) {
  let {access, refresh, ...userData} = sessionData
  localStorage.setItem('access_token', access)
  localStorage.setItem('refresh_token', refresh)
  localStorage.setItem('usuario', JSON.stringify(userData))
  state.accessToken = access 
  state.refreshToken = refresh
  state.userData = userData
  state.status = 'success'
}

export function setCsT(state, csrfToken) {
  localStorage.setItem('csT', csrfToken)
  state.csT = csrfToken
}

export function updateAccess(state, access) {
  state.accessToken = access
  localStorage.setItem('access_token', access)
}

export function authRequest(state) {
  state.status = 'loading'
}

export function authError(state) {
  state.status = 'error'
}

export function authLogout(state) {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('usuario')
  localStorage.removeItem('userAdress')
  localStorage.removeItem('userExtraInfo')
  localStorage.removeItem('csT')
  localStorage.removeItem('customerDiscount')
  state.accessToken = null
  state.refreshToken = null
  state.userFiscales = null
  state.clienteId = null
  state.csT = null
  state.userData = null
  state.userAddress = null
  state.customerDiscount = null
  router.push({ name: 'Construfácil' })
}

export function updateSelectedAddress(state, newAddress) {
  state.userAddress = newAddress
  localStorage.setItem('userAddress', JSON.stringify(newAddress))
}

export function setCustomerDiscount(state, discount) {
  state.customerDiscount = discount
  localStorage.setItem('customerDiscount', JSON.stringify(discount))
}

export function updateUserData(state, userData) {
  state.userData = userData
  localStorage.setItem('usuario', JSON.stringify(userData))
}

export function setStripeAccountComplete(state, bool) {
  state.stripeAccountComplete = bool
}

export function setWishlist(state, payload) {
  state.wishlist = payload
}

export function addProductToWishlist(state, product) {
  state.wishlist.push(product)
}
