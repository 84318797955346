export async function distanciaSucursalCliente({commit, rootState}) {
  const loader = rootState.mapsLoader
  // let dirCliente = JSON.parse(localStorage.getItem('userAddress'))
  let dirCliente = rootState.usuario.userAddress
  let sucursal = rootState.cart.cart[0].almacen.sucursal.direccion
  let direccionSucursal = `${sucursal.calle},${sucursal.numero_exterior},${sucursal.colonia},${sucursal.codigo_postal},${sucursal.estado},${sucursal.ciudad}`
  let direccionCliente = `${dirCliente.calle},${dirCliente.numero_exterior},${dirCliente.colonia},${dirCliente.codigo_postal},${dirCliente.estado},${dirCliente.ciudad}`

  let clienteLat = 0 
  let clienteLng = 0
  let sucursalLat = 0
  let sucursalLng = 0

  await loader.load().then(async() => {
    let geocoder = new google.maps.Geocoder();
    await geocoder.geocode( { 'address': direccionSucursal }, async function(results, status) {
      sucursalLat = results[0].geometry.location.lat()
      sucursalLng = results[0].geometry.location.lng()
      await geocoder.geocode({ 'address': direccionCliente }, async function(results, status) {
        clienteLat = results[0].geometry.location.lat()
        clienteLng = results[0].geometry.location.lng()
        // console.log("sucursal lat lng", sucursalLat,  sucursalLng)
        // console.log("cliente lat lng", clienteLat, clienteLng)

        let service = new google.maps.DistanceMatrixService()
        await service.getDistanceMatrix(
          {
            origins: [new google.maps.LatLng(clienteLat, clienteLng)],
            destinations: [new google.maps.LatLng(sucursalLat, sucursalLng)],
            travelMode: 'DRIVING',
            //transitOptions: TransitOptions,
            //drivingOptions: DrivingOptions,
            //unitSystem: UnitSystem,
            avoidHighways: true,
            //avoidTolls: Boolean,
          },async function(result, status) {
            let distanciaStr = result.rows[0].elements[0].distance.text
            let sliceDistance = distanciaStr.split(" ")
            let distanciaFinal = sliceDistance[0]
            commit('setDistancia', parseInt(distanciaFinal))
          }
        )
        /*
          * Lo siguiente lo dejo comentado por si quieren obtener la distancia en línea recta (curva),
          * Con el proceso de arriba obtenemos la distancia pero tomando en cuenta factores como, carreteras,
          * cómo se va a mover el distribuidior(coche,etc.) y otras características.
        */
        // let probando = google.maps.geometry.spherical.computeDistanceBetween(
        //   new google.maps.LatLng(clienteLat, clienteLng),
        //   new google.maps.LatLng(sucursalLat, sucursalLng)
        // )
        // console.log("probandoo", probando)

      })
    })
  })
}